<i18n lang="yaml">
  # utils.js contains the fallback translations for statuses
  en:
    unit-type: 'Unit Type'
  fi:
    unit-type: 'Tyyppi'
</i18n>

<script>
  import Modal from './Modal.vue';
  import Carousel from './Carousel.vue';
  import FilterItems from './FilterItems.vue';
  import marked from 'marked';

  export default {
    name: "UnitModal",
    computed: {
      unit: {
        get() {
          const unit = this.$store.state.unit;
          if (unit !== null && this.$refs.modal) {
            this.$refs.modal.show();
          } else {
            this.unitType = null;
          }
          return unit;
        },
        set(value) {
          this.$store.dispatch('setUnit', value);
        }
      },
      data() {
        return this.$store.state.unitData[this.unit] || {};
      },
      typeData() {
        return this.$store.state.unitTypeData[this.activeUnitType] || {};
      },
      slides() {
        const slides = [];
        if (this.data.media) slides.push(...this.data.media);
        if (this.typeData.media) slides.push(...this.typeData.media);
        return slides;
      },
      markdownBody() {
        return marked(this.typeData.description + '\n\n' + this.data.body)
      },
      unitTypes() {
        return Object.entries(this.$store.state.unitTypeData).map(([ id, data ]) => ({ id, label: data.name }))
      },
      activeUnitType() {
        return this.unitType || this.data.unitType;
      }
    },
    components: {
      Modal,
      Carousel,
      FilterItems
    },
    data: function () {
      return {
        unitType: null,
        filtered: [],
        videoId: null
      }
    }
  };
</script>

<template>
  <Modal ref='modal' @hidden='unit = null' size='large' :center='true'>
    <div class='row'>
      <div class='col-md-3 mb-3 mb-lg-0 modal-body__info'>
        <h5 class="modal-title">{{ data.name }}<small class="text-muted float-right">{{ $t(data.status) }}</small></h5>
        <FilterItems @filtered='filtered = $event' :items='slides' class='mb-3'>
          <template v-slot:before>
            <!-- This is currently hidden in turku.scss -->
            <div ref='unit-type' class='dropdown' id="unit-type-filter-dropdown">
              <button class='btn btn-secondary dropdown-toggle' type='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>{{ $t('unit-type') }}: {{ typeData.name }}</button>
              <div class='dropdown-menu'>
                <button v-for='({ id, label }, index) of unitTypes' :key='index' :ref='`unit-type-${id}`' @click='unitType = id' :class='{ active: id == activeUnitType }' class='dropdown-item' type='button' >{{ label }}</button>
              </div>
            </div>
          </template>
        </FilterItems>
        <div v-if='markdownBody' v-html="markdownBody"></div>
      </div>
      <div class='col modal-body__image my-n3'>

        <Carousel v-if='filtered.length' :slides='filtered' :indicators='true' :interval=0 />
      </div>
    </div>
    <div v-if = 'videoId !== null' class='row'>
      <youtube :video-id="videoId"></youtube>
    </div>
  </Modal>
</template>
