<template>
  <div class='btn-toolbar' role='toolbar'>
    <slot name='before' />
    <div v-for='([ filter, { options } ], index) in Object.entries(filters)' :key='filter' class='dropdown'>
      <button class='btn btn-secondary dropdown-toggle' type='button' data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>{{ buttonLabel(filter) }}</button>
      <div class='dropdown-menu'>
        <button v-for='({ id, label }, index) in options' :key='index' :ref='`filter-${filter}-${id}`' class='dropdown-item' :class='{ active: id === state[filter] }' type='button' @click='state[filter] = id'>{{ label }}</button>
      </div>
    </div>
    <slot name='after' />
  </div>
</template>

<i18n lang="yaml">
  en:
    tag: 'Tag'
    all: 'All'
    image: 'Images'
    video: 'Videos'
    all-media: 'Images and videos'
  fi:
    tag: 'Tagi'
    all: 'Kaikki'
    image: 'Kuvat'
    video: 'Videot'
    all-media: 'Kuvat ja videot'
</i18n>

<script>
import { Tag } from 'models'

export default {
  name: 'FilterItems',
  props: {
    items: {
      type: Array,
      default: []
    },
    filters: {
      type: Object,
      default() {
        return {
          tags: {
            label: this.$t('tag'),
            options: [
              { id: null, label: this.$t('all') }
            ]
          },
          type: {
            options: [
              { id: null, label: this.$t('all-media') },
              { id: 'image', label: this.$t('image') },
              { id: 'video', label: this.$t('video') }
            ]
          }
        }
      }
    }
  },
  data() {
    return {
      state: Object.keys(this.filters).reduce((filters, filter) => ({ ...filters, [filter]: null }), {})
    }
  },
  watch: {
    items: 'update',
    state: {
      deep: true,
      immediate: true,
      handler: 'update'
    }
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => {
        for (let [ filter, active ] of Object.entries(this.state)) {
          if (active !== null) {
            if (Array.isArray(item[filter])) {
              if (item[filter].indexOf(active) == -1) return false;
            } else if (item[filter] != active) return false;
          }
        }
        return true;
      })
    }
  },
  mounted: async function() {
    const { data: tags } = await Tag.all()
    tags.forEach(tag => {
      this.filters.tags.options.push({
        id: tag.id,
        label: tag.name
      })
    })
  },
  methods: {
    update() {
      this.$emit('filtered', this.filteredItems);
    },
    buttonLabel(filter) {
      const parts = [ this.filters[filter].options.find(option => option.id == this.state[filter]).label ];
      if (this.filters[filter].label) parts.unshift(this.filters[filter].label);
      return parts.join(': ');
    }
  }
}
</script>
