<i18n lang="yaml">
  en:
    go-up: 'Go upstairs'
    go-down: 'Go downstairs'
  fi:
    go-up: 'Mene yläkertaan'
    go-down: 'Mene alakertaan'
</i18n>

<script>
  export default {
    name: "Navigator",
    computed: {
      building() { return this.$store.state.building },
      buildings() { return this.$store.state.buildings.length },
      floor: {
        get() { return this.$store.state.floors.indexOf(this.$store.state.floor); },
        set(value) {
          if (value < 0) this.$store.dispatch('setBuilding', -1)
          else this.$store.dispatch('setFloor', value);
          this.setTooltip()
        }
      },
      floors() { return this.$store.state.floors.length; },
      useToggle() { return this.floors == 2 && this.buildings == 1 },
      minFloor() { return this.buildings > 1 ? -1 : 0 },
      title() {
        // Update tooltip if it's open
        if (this.$refs.toggle && this.$refs.toggle.getAttribute('aria-describedby')) {
          this.$nextTick(() => $(this.$refs.toggle).tooltip('show'));
        }
        return this.$t(this.floor == 0 ? 'go-up' : 'go-down');
      },
      showName() {
        // LUV Buildings
        return [
          '9c0de6c1-1c34-4377-8996-b4d068d51401',
          'd05e03b0-fc8a-4b39-9286-f9ebe2d64198',
          '31ef4a00-ea44-4088-89f5-385ed213b36e'
        ].indexOf(this.building.id) !== -1
      }
    },
    methods: {
      setTooltip() {
        $(this.$refs.toggle).tooltip();
      }
    },
    mounted() {
      this.setTooltip()
    },
  };
</script>

<template>
  <div v-if='useToggle' ref='toggle' class='floor-toggle' :class='{ reverse: floor }' @click='floor = floor ? 0 : 1' :data-original-title='title'>
    <svg viewBox="-2 -2 64 64">
      <circle x="30" y="30" cx="30" cy="30" r="30" fill="none" stroke-width="4" />
      <path d="M17.5,47.5L32.5,47.5L32.5,32.5L47.5,32.5L47.5,17.5L62.5,17.5L62.5,62.5L17.5,62.5L17.5,47.5Z" stroke='none'/>
      <g>
        <path d="M15,30L30,15" fill='none' stroke-width='4'/>
        <path d="M22.5,12.5L32.5,12.5L32.5,22.5" stroke='none'/>
      </g>
    </svg>
  </div>
  <div v-else-if='building && (floors > 2 || buildings > 1)' class='btn-group'>
    <button class='btn btn-secondary floor-down' :disabled='floor <= minFloor' @click='floor--'>-</button>
    <div class='floor-number p-2 bg-white'>{{ floor + 1 }}</div>
    <button class='btn btn-secondary floor-up' :disabled='floor >= floors - 1' @click='floor++'>+</button>
    <p class='ml-2 h-100 my-auto' v-if="showName">{{ building.name }}</p>
  </div>
</template>

<style lang='scss'>
  .floor-toggle {
    border-radius: 50%;
    cursor: pointer;
    fill: currentColor;
    overflow: hidden;
    stroke: currentColor;

    & > svg {
      display: block;
      max-width: 54px;
      min-width: 29px;
      transition: transform 0.25s ease-out;
      width: 10vw;
    }

    &.reverse > svg {
      transform: rotate(90deg);
    }
  }
</style>
