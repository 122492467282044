import Vue from 'vue'

function updateData(storeObject, id, data) {
  Vue.set(storeObject, id, Object.assign(
    {},
    storeObject[id] || {},
    data
  ))
}

export default {
  setBuilding(state, value) { state.building = value },
  setBuildings(state, value) { state.buildings = value },
  setFloor(state, value) { state.floor = value },
  setFloors(state, value) { state.floors = value },
  setUnit(state, value) { state.unit = value },
  updateUnitData(state, { id, ...data }) { updateData(state.unitData, id, data) },
  updateUnitTypeData(state, { id, ...data }) { updateData(state.unitTypeData, id, data) }
}
