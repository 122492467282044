import { csrfToken } from '@rails/ujs'
import { SpraypaintBase, attr, belongsTo, hasMany, MiddlewareStack } from 'spraypaint'

const ApplicationRecord = SpraypaintBase.extend({
  static: {
    baseUrl: '',
    apiNamespace: '/api/v1'
  }
})

const middleware = new MiddlewareStack()

middleware.beforeFilters.push(function(url, options) {
  options.headers['X-CSRF-Token'] = csrfToken()
})

ApplicationRecord.middlewareStack = middleware

function buildModel(jsonapiType, options) {
  const merged = Object.assign(
    {
      static: {
        jsonapiType
      }
    },
    options
  )
  return ApplicationRecord.extend(merged)
}

const Site = buildModel('sites', {
  attrs: {
    name: attr(),
    buildings: hasMany()
  }
})

const Building = buildModel('buildings', {
  attrs: {
    name: attr(),
    site: belongsTo(),
    floors: hasMany()
  }
})

const Floor = buildModel('floors', {
  attrs: {
    name: attr(),
    building: belongsTo(),
    units: hasMany()
  }
})

const UnitType = buildModel('unit_types', {
  attrs: {
    name: attr(),
    description: attr(),
    media: attr()
  }
})

const Unit = buildModel('units', {
  attrs: {
    name: attr(),
    body: attr(),
    status: attr(),
    media: attr(),
    floor: belongsTo(),
    unitType: belongsTo()
  }
})

const Tag = buildModel('tags', {
  attrs: {
    name: attr()
  }
})

const MapLegend = buildModel('map_legends', {
  attrs: {
    legends: attr(),
    available: attr(),
    reserved: attr(),
    unavailable: attr(),
    signed: attr(),
    contactMessage: attr(),
    i18n: attr()
  }
})

$(async() => {
  if (!$('#sites_in_system').length) {
    return
  }

  const { data } = await Site.all()
  data.forEach((site) => {
    $('#sites_in_system').append(`<p>${site.name}</p>`)
  })

  const newSite = new Site({ name: 'New Site From JS' })
  await newSite.save()
  $('#sites_in_system').append(`<p>${newSite.name}</p>`)
  newSite.name = 'Updated Site From JS'
  await newSite.save()

  Site.find(newSite.id) // testing find

  const invalid = new Site()
  const createWorked = await invalid.save()
  $('#sites_in_system').append(`<p>Invalid create result: ${createWorked}</p>`)

  newSite.name = null
  const updateWorked = await newSite.save()
  $('#sites_in_system').append(`<p>Invalid update result: ${updateWorked}</p>`)

  await newSite.destroy()
  $('#sites_in_system').append('<p>Deleted Updated Site From JS</p>')

  const { data: unitTypes } = await UnitType.all()
  for (const unitType of unitTypes) {
    $('#unit_types_in_system').append(`<p>${unitType.name}</p>`)
  }

  const response = await Site.includes({ buildings: { floors: { units: 'unit_type' } } }).all()
  response.data.forEach((s) => {
    s.buildings.forEach((b) => {
      b.floors.forEach((c) => {
        c.units.forEach((u) => {
          const unitElem = $(`<div id="unit-${u.id}"></div>`)
          $('body').append(unitElem)
          u.media.forEach((m) => {
            const image = $('<img/>')
              .on('load', function() { $(this).addClass('load-success') })
              .attr('src', m.url)
            unitElem.append(image)
          })
        })
      })
    })
  })
})

export {
  Site,
  Building,
  Floor,
  UnitType,
  Unit,
  Tag,
  MapLegend
}
