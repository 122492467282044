<template>
  <div class="container">
    <div id="app">
      <div v-if='loaded' class="row justify-content-start flex-column-reverse flex-lg-row">
        <div class="col-lg-3 align-self-center offset-lg-1 mt-lg-3 mt-2">
          <MapLegends />
        </div>
        <div class="col-lg-5 arena-container">
          <Navigator class='fade-in' />
          <Map class='fade-in' />
          <UnitModal />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import { Site, UnitType } from './models';
  import Navigator from './components/Navigator';
  import UnitModal from './components/UnitModal';
  import MapLegends from './components/MapLegends';
  let mapName
  const siteMeta = $('meta[name=site_name]')

  if (siteMeta.length) {
    mapName = siteMeta.attr('content')
  } else {
    mapName = window.location.search.match(/(?:\?|&)map=([^&]*)/)
    mapName = mapName ? mapName.pop() : 'arena'
  }

  export default {
    name: "App",
    components: {
      Navigator,
      UnitModal,
      Map: () => import(/* webpackChunkName: "[request]" */ `./components/Maps/Map-${mapName}`),
      MapLegends
    },
    data() {
      return {
        loaded: false
      }
    },
    async created() {
      this.$root.$once('ready-for-image-preload', function () {
        // We do this here instead of head because otherwise the pipeline
        // can be full of these delaying the ajax calls to API above
        for(const elem of document.querySelectorAll('[data-preload-image-href]')) {
          const preloadLink = document.createElement("link");
          preloadLink.href = elem.dataset.preloadImageHref;
          preloadLink.rel = "preload";
          preloadLink.as = "image";
          preloadLink.type = 'image/jpeg';
          document.head.appendChild(preloadLink);
        }
      })

      let dispatches = []

      const data = await Promise.all([
        UnitType.all(),
        Site
          .where({ name: this.site })
          .includes({ buildings: { floors: { units: 'unit_type' } } })
          .select({ unit_types: 'id' })
          .first()
      ]).then(([{ data: unitTypes }, { data: site }]) => {
        if (!site) return null

        dispatches.push(this.$store.dispatch('setBuildings', site.buildings))
        if (site.buildings.length == 1) dispatches.push(this.$store.dispatch('setBuilding', 0))

        return {
          updateUnitTypeData: unitTypes,
          updateUnitData: site.buildings.map(building => building.floors.map(floor => floor.units)).flat(2)
        }
      })

      Object.entries(data).forEach(([ mutation, entries ]) => entries.forEach(entry => {
        this.$store.commit(mutation, Object.assign(
          { id: entry.id },
          entry.attributes,
          Object.entries(entry.relationships).reduce((obj, [ key, { id }]) => ({ ...obj, [key]: id }), {})
        ));
      }));

      // You could also nowadays inline the event code here but keeping emit makes it easy to move the trigger
      // elsewhere
      this.$root.$emit('ready-for-image-preload')

      Promise.all(dispatches).then(values => {
        $('.luv-building-nav-link').removeClass('d-none')
      })

      this.loaded = true
    }
  };
</script>
