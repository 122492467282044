<script>
  export default {
    name: 'Carousel',
    props: {
      slides: { type: Array, default: () => [] },
      indicators: { type: Boolean, default: false },
      controls: { type: Boolean, default: true },
      interval: { type: Number, default: 5000 },
      keyboard: { type: Boolean, default: true },
      pause: { default: 'hover' },
      ride: { type: String, default: 'false' },
      wrap: { type: Boolean, default: true },
      start: { type: Number, default: 0 }
    },
    data() {
      return {
        width: 0,
        maxImageRatio: 0.66
      }
    },
    computed: {
      maxHeight() { return Math.min(this.maxImageRatio * this.width, 2200) }
    },
    watch: {
      slides: {
        immediate: true,
        handler() {
          $(this.$el).carousel(0)
          this.updateImageRatio()
        }
      }
    },
    methods: {
      updateWidth() {
        this.width = this.$el.offsetWidth;
      },
      async updateImageRatio() {
        const ratios = await Promise.all(this.slides.map(slide => new Promise(resolve => {
          const img = new Image()
          img.onload = () => { resolve(img.height / img.width) }
          img.src = slide.url
        })))

        this.maxImageRatio = Math.max(...ratios)
      }
    },
    mounted() {
      window.addEventListener('resize', this.updateWidth);
      const waitParent = () => {
        if (this.$el) this.$el.offsetParent
          ? this.updateWidth()
          : requestAnimationFrame(waitParent)
      }
      waitParent()
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateWidth);
    }
  };
</script>

<template>
  <div :id='`carousel-${_uid}`' class='carousel slide' :data-interval='`${interval}`' :data-keyboard='`${keyboard}`' :data-pause='`${pause}`' :data-ride='`${ride}`' :data-wrap='`${wrap}`'>
    <ol v-if='indicators' class='carousel-indicators'>
      <li v-for='(slide, index) in slides' :key='index' :data-target='`#carousel-${_uid}`' :data-slide-to='index' :class='{ active: index == start }' />
    </ol>
    <div class='carousel-inner'>
      <div v-for='(slide, index) in slides' :key='slide.url' class='carousel-item' :class='{ active: index == start }'>
        <img v-if='slide.type == "image"' class='carousel__img' :src='slide.jpeg_modal_downscale' :style='`height: ${maxHeight}px`'>
        <youtube v-else class="carousel__video" :video-id='slide.url' />
      </div>
    </div>
    <a v-if='controls' class='carousel-control-prev' :href='`#carousel-${_uid}`' role='button' data-slide='prev'>
      <span class='carousel-control-prev-icon' aria-hidden='true'></span>
      <span class='sr-only'>Previous</span>
    </a>
    <a v-if='controls' class='carousel-control-next' :href='`#carousel-${_uid}`' role='button' data-slide='next'>
      <span class='carousel-control-next-icon' aria-hidden='true'></span>
      <span class='sr-only'>Next</span>
    </a>
  </div>
</template>

<style lang='scss'>
  .video {
    position: relative;
    height: 0;

    & > iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  .carousel__img {
    width: 100%;
    max-height: calc(100vh - 3.5rem);
    object-fit: contain;
  }
</style>
