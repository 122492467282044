
<template>
<div class="jumbotron p-1 text-center mb-0">
  <h5>{{ $t('legends') }}</h5>
  <h5>
    <span class="badge map-legends__available-badge">
      {{ $t('available') }}
    </span>
    <span class="badge map-legends__reserved-badge">
      {{ $t('reserved') }}
    </span>
    <span class="badge map-legends__unavailable-badge">
      {{ $t('unavailable') }}
    </span>
    <span class="badge map-legends__signed-badge">
      {{ $t('signed') }}
    </span>
  </h5>
  <p class="mt-2 map-legends__contact-message">
    {{ $t('contact_message') }}
  </p>
</div>
</template>

<script>
export default {
  name: 'MapLegends'
}
</script>

<style scoped>
  .map-legends__contact-message {
    font-size: 0.8rem;
  }
</style>
