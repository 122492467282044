<script>
  export default {
    name: "Modal",
    props: {
      fade: {
        type: Boolean,
        default: true
      },
      size: String,
      center: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        visible: false
      };
    },
    computed: {
      dialogClasses() {
        return {
          'modal-sm': this.size == 'small',
          'modal-lg': this.size == 'large',
          'modal-dialog-centered': this.center
        }
      }
    },
    methods: {
      show() {
        this.visible = true;
        this.$nextTick(() => {
          $(this.$refs.modal)
            .one('hidden.bs.modal', event => {
              this.$emit('hidden');
              this.visible = false;
            })
            .modal('show');
        });
      },
      hide() {
        $(this.$refs.modal).modal('hide');
      }
    }
  };
</script>

<template>
  <div v-if='visible' ref='modal' class="modal" :class='{ "fade": fade }' tabindex="-1" role="dialog">
    <div class="modal-dialog" :class='dialogClasses' role="document">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div v-if='$slots.title' class="modal-header">
          <h5 class="modal-title">
            <slot name='title'></slot>
          </h5>
        </div>
        <div class="modal-body">
          <slot>Loading&hellip;</slot>
        </div>
      </div>
    </div>
  </div>
</template>
