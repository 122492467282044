import { MapLegend } from 'models'

import Vue from 'vue'

import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

async function initI18n() {
  const { data: [mapLegend] } = await MapLegend.all()

  // Should look into making mapLegend always exist so the fallback
  // can be dropped (mostly an issue in tests)
  const fallbackTranslations = {
    // these should stay in sync with db/schema.rb map_legend defaults
    en: {
      available: 'available',
      unavailable: 'sold',
      reserved: 'reserved',
      signed: 'signed'
    },
    fi: {
      available: 'vapaa',
      unavailable: 'myyty',
      reserved: 'varattu',
      signed: 'vuokrattu'
    }
  }

  let statusTranslations

  // Our stack should move to support conditional chaining here
  if (mapLegend) {
    statusTranslations = mapLegend.i18n
  } else {
    statusTranslations = fallbackTranslations
  }

  return new VueI18n({
    locale: 'fi',
    messages: statusTranslations
  })
}

export function initVue(component, extras) {
  document.addEventListener('turbolinks:load', async() => {
    const i18n = await initI18n()
    const app = new Vue(
      Object.assign({
        i18n,
        render: h => h(component)
      }, extras)
    ).$mount()
    window.TOOLI = app
    document.body.appendChild(app.$el)
  })
}
