const getByIndexOrId = (array, value) => {
  return typeof value === 'string'
    ? array.find(item => item.id === value)
    : array[value]
}

export default {
  setBuilding({ commit, state }, value) {
    const building = getByIndexOrId(state.buildings, value) || null
    commit('setBuilding', building)
    commit('setFloors', building ? building.floors : [])
    commit('setFloor', building ? building.floors[0] : {})
  },
  setBuildings({ commit }, value) {
    commit('setBuildings', value)
  },
  setFloor({ commit, state }, value) {
    commit('setFloor', getByIndexOrId(state.floors, value) || {})
  },
  setFloors({ commit }, value) {
    commit('setFloors', value)
  },
  setUnit({ commit, dispatch }, value) {
    commit('setUnit', value)
  }
}
